import Vue from 'vue'
import VueRouter from 'vue-router'
const login = () => import('../views/login/Index.vue');
const main = () => import('../views/main/Index.vue');
const home = () => import('../views/main/home/Index.vue');
// const department = () => import('../views/main/department/Index.vue');
// const user = () => import('../views/main/user/Index.vue');
// const applet = () => import('../views/main/applet/index.vue');
// const role = () => import('../views/main/role/Index.vue');
// const menu = () => import('../views/main/menu/Index.vue');
// const log = () => import('../views/main/log/Index.vue');
// const versions = () => import('../views/main/versions/Index.vue');
// const about = () => import('../views/main/about/Index.vue');
// const project = () => import('../views/main/project/Index.vue');
// const wechat = () => import('../views/main/wechat/Index.vue')
// const product = () => import('../views/main/product/Index.vue');

Vue.use(VueRouter)

const routes = [
  { path: '/login', name: 'login', component: login, meta: { title: '登录页' } },
  {
    path: '/', name: 'main', props: true, component: main, meta: { title: '首页' }, children: [
      {
        path: '/', name: 'home',component: home, meta: { title: '用户中心' }
      },
      // {
      //   path: 'department', component: department, meta: { title: '部门管理' }
      // },
      // {
      //   path: 'user', component: user, meta: { title: '用户管理' }
      // },
      // {
      //   path: 'applet', component: applet, meta: { title: '小程序用户管理' }
      // },
      // {
      //   path: 'wechat', component: wechat, meta: { title: '微信用户管理' }
      // },
      // {
      //   path: 'role', component: role, meta: { title: '角色管理' }
      // },
      // {
      //   path: 'menu', component: menu, meta: { title: '菜单管理' }
      // },
      // {
      //   path: 'project', component: project, meta: { title: '项目管理' }
      // },
      // {
      //   path: 'product', component: product, meta: { title: '产品管理' }
      // },
      // {
      //   path: 'log', component: log, meta: { title: '日志管理' }
      // },
      // {
      //   path: 'versions', component: versions, meta: { title: '版本管理' }
      // },
      // {
      //   path: 'about', component: about, meta: { title: '关于平台' }
      // },
    ]
  }
]

// 修复route跳转相同路径下报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
