import router from '../router';
import API from './api';
// import util from '../util/help.js';
import { Loading } from 'element-ui';

const axiosRouterList = () => {
    return new Promise((resolve, reject) => {
        API.getRouterAndMenu({}).then( d => {
            localStorage.setItem('router', JSON.stringify(d.routeList));
            localStorage.setItem('menu-list', JSON.stringify(d.menuList));
            resolve(d.routeList)
        }).catch(() => {
            reject()
        })
    })
}

function filterAsyncRouter(asyncRouterMap) { //遍历后台传来的路由字符串，转换为组件对象
    const accessedRouters = asyncRouterMap.filter(route => {
        if (route.component) {
           route.component = loadView(route.component)
        }
        if (route.children && route.children.length) {
            route.children = filterAsyncRouter(route.children)
        }
        return true
    })
    return accessedRouters
}

// 拼接路径 路由懒加载
const loadView = (view) => {
    return (resolve) => require([`@/views/main/${view}`], resolve)
}

let getRouter = null;
router.beforeEach(async (to, from, next) => {
    /* 路由发生改变修改页面的title */
    document.title = to.meta.title || '用户中心';

	const token = window.localStorage.getItem('Tit-token')
    if(token && to.path !== "/login" ){
        if (!getRouter) {
            // 过渡效果
            const loading = Loading.service({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });
            setTimeout(() => {
                loading.close();
            }, 1000);
            let routeList = await axiosRouterList();
            routeList = filterAsyncRouter(routeList)
            
            routeList.map( item => {
                router.addRoute('main',item) //动态添加路由
            })
            getRouter = true;  // 刷新加载一次
            next({ ...to, replace: true })
        } else {
            next()
        }
    }
    // 集中处理单点登录权限操作
    // let obj = util.urlParse();
    // if(obj){
    //     let info = {...obj};
    //     if(info?.code){
    //         const loading = Loading.service({
    //             lock: true,
    //             text: 'Loading',
    //             spinner: 'el-icon-loading',
    //             background: 'rgba(255, 255, 255, 0.7)'
    //         });
    //         setTimeout(() => {
    //             loading.close();
    //         }, 2000);
    //         delete info.state;
    //         info.redirectUri = window.location.host;
    //         const tokenData = await API.getCode(info);
    //         // 修复-双重保重，考虑codeCallback接口空值导致的页面导航失效问题
    //         if(tokenData === undefined){
    //             next();
    //             return false;
    //         }
    //         window.sessionStorage.setItem('Tit-token',tokenData.data.token);
    //         // 修复-获取code码清除地址之后页面地址同步刷新问题
    //         setTimeout(()=>{
    //             history.replaceState({},'',location.href.replace(location.search,''))
    //         },500)
    //         const userData = await API.getUserInfo();
    //         window.sessionStorage.setItem('USERINFO',JSON.stringify(userData.data?.attributes));
    //     }
    // }else{
    //     let USERINFO = window.sessionStorage.getItem('USERINFO');
    // if(!USERINFO){
    //     const userData = await API.getUserInfo();
    //     window.sessionStorage.setItem('USERINFO',JSON.stringify(userData.data?.attributes));
    // }
    // }
    next();
})
